import Splitting from "../../../_snowpack/pkg/splitting.js";
import gsap from "../../../_snowpack/pkg/gsap.js";

import Slideshow from "./Slideshow.js";
//import Cursors from "./Cursors";
import { preloadImages } from "./utils/index.js";

// Get all "navbar-burger" elements
const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

// Check if there are any navbar burgers
if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach(el => {
        el.addEventListener('click', () => {

            // Get the target from the "data-target" attribute
            const target = el.dataset.target;
            const $target = document.getElementById(target);

            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
            el.classList.toggle('is-active');
            $target.classList.toggle('is-active');

        });
    });
}


const $linkBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-item '), 0);
// Check if there are any navbar burgers
if ($linkBurgers.length > 0) {

    // Add a click event on each of them
    $linkBurgers.forEach(el => {
        el.addEventListener('click', () => {

            // Get the target from the "data-target" attribute
            const $targetnav = document.getElementById("navbarList");

            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
            $navbarBurgers.forEach(el => {
                el.classList.toggle('is-active');
            })
            $targetnav.classList.toggle('is-active');

        });
    });
}
export function homeSlide(selector) {
    Splitting();
    //colors
    const graphCol = "#AC85DA";
    const mecaCol = "#42D587";
    const outdoorCol = "#F9C100";
    const indusCol = "#E51616";
    const graphColBg = "#010101";
    const mecaColBg = "#1f1322";
    const outdoorColBg = "#454d53";
    const indusColBg = "#2d1f2d";


    const randomX = random(1, 10);
    const randomY = random(1, 10);
    const randomDelay = random(0, 1);
    const randomTime = random(3, 5);
    const randomTime2 = random(5, 10);
    const randomAngle = random(-10, 10);

    const cans = gsap.utils.toArray('.trSvg');
    cans.forEach(can => {
        gsap.set(can, {
            x: randomX(-1),
            y: randomX(1),
            rotation: randomAngle(-5)
        });

        moveX(can, 5);
        moveY(can, -5);
        rotate(can, 5);
    });

    function rotate(target, direction) {

        gsap.to(target, randomTime2(), {
            rotation: randomAngle(direction),
            // delay: randomDelay(),
            ease: "Sine.easeInOut",
            onComplete: rotate,
            onCompleteParams: [target, direction * -1]
        });
    }

    function moveX(target, direction) {

        gsap.to(target, randomTime(), {
            x: randomX(direction),
            ease: "Sine.easeInOut",
            onComplete: moveX,
            onCompleteParams: [target, direction * -1]
        });
    }

    function moveY(target, direction) {

        gsap.to(target, randomTime(), {
            y: randomY(direction),
            ease: "Sine.easeInOut",
            onComplete: moveY,
            onCompleteParams: [target, direction * -1]
        });
    }

    function random(min, max) {
        const delta = max - min;
        return (direction = 1) => (min + delta * Math.random()) * direction;
    }




    const trColors = [graphCol, mecaCol, outdoorCol, indusCol];

    const bgColors = [graphColBg, mecaColBg, outdoorColBg, indusColBg];



    const masterTl = gsap.timeline();
    if (document.querySelectorAll(".slider__image").length > 0) {
        preloadImages(document.querySelectorAll(".slider__image")).then(() => {
            const slider = new Slideshow(document.querySelector(".slider"));
            slider.init();
            const loadedAnimationTl = gsap
                .timeline({
                    onStart: () => {

                    },
                })
                .to(".loading__text", {
                    duration: 1,
                    opacity: 0,
                })
                .to(".bg__transition--slide", {
                    duration: 1,
                    scaleY: 0,
                    transformOrigin: "top center",
                    ease: "expo.out",
                    onComplete: () => {
                        slider.initAnimation();
                        gsap.set(".loading__wrapper", {
                            pointerEvents: "none",
                            autoAlpha: 0,
                        });
                    },
                });

            const pageAnimationTl = gsap
                .timeline({
                    delay: loadedAnimationTl.duration(),
                    onComplete: () => {
                        //Cursors.init();
                        //Cursors.initEventsOnSlider(slider);
                    },
                })
                .from([".frame__rights > span", ".frame__social > span"], {
                    duration: 1,
                    opacity: 0,
                    yPercent: 100,
                    stagger: 0.1,
                    ease: "expo.out",
                });

            masterTl.add(loadedAnimationTl, 0);
            masterTl.add(pageAnimationTl, pageAnimationTl.duration() - 0.5);

            slider.onSlideChange((currentSlideIndex) => {
                gsap.to("body", {
                    duration: 1.2,
                    backgroundColor: bgColors[currentSlideIndex],
                });
                gsap.to(".trFill", {
                    duration: 1.2,
                    fill: trColors[currentSlideIndex],
                });
            });
        });
    }
};