import gsap from '../../../../_snowpack/pkg/gsap.js';

const leaveToProject = (container) => {
	const loadingScreen = document.querySelector('.loading-screen')
	const tl = gsap.timeline({
		delay: 0.2,duration: 0.1, ease: 'power4.out'
	});
	tl
		.to(loadingScreen, {  scaleY: 1, transformOrigin: 'bottom left'});
	return tl;
};

export default leaveToProject;