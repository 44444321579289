import gsap from '../../../../_snowpack/pkg/gsap.js';

const revealProject = (container) => {
	const loadingScreen = document.querySelector('.loading-screen');
	const bod = document.querySelector('body');
	bod.removeAttribute("style");
	const tl = gsap.timeline({
		delay: 0.2,duration: 0.5, ease: 'power4.out'
	});
	tl
		.to(loadingScreen, {
			scaleY: 0,
			skewX: 0,
			transformOrigin: 'top left',
			ease: 'power1.out'});
	return tl;
};

export default revealProject;