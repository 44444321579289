import barba from '../../../_snowpack/pkg/@barba/core.js';
import Home from './pages/home.js';
import About from './pages/detail-page.js';
import gsap from '../../../_snowpack/pkg/gsap.js';
import { homeSlide } from "../three-slider/index.js";
import { revealProject, leaveToProject, leaveFromProject, animationEnterMask, animationLeaveMask } from './animations/index.js';

document.querySelectorAll(".cms-toolbar a").forEach(item=>item.setAttribute('data-barba-prevent','self'));


const loadingScreen = document.querySelector('.loading-screen');
const mainNavigation = document.querySelector('.main-navigation');

barba.hooks.enter(() => {
    window.scrollTo(0, 0);
});

barba.init({
    debug: true,
    views: [Home, About],
    transitions: [
        {
            name: 'general-transition',
            to: {
                namespace: ['home']
            },
            once: ({ next }) => {
                //resetActiveLink();
                animationEnterMask(next.container);
                //gsap.from('header a', {
                //    duration: 0.6,
                //    yPercent: 100,
                //    stagger: 0.2,
                //    ease: 'power1.out',
                //    onComplete: () => animationEnterMask(next.container)
                //});
            },
            leave: ({ current }) => animationLeaveMask(current.container),
            enter: ({ next }) => {
                animationEnterMask(next.container);console.log('general-transition');
            }
        }, {
            name: 'detail',
            to: {
                namespace: ['detail']
            },
             once: ({ next }) => {
                 revealProject(next.container);
             },
             leave: ({ current }) => leaveToProject(current.container),
             enter: ({ next }) => {
                 revealProject(next.container)
             }
        }, {
            name: 'from-detail',
            from: {
                namespace: ['detail']
            },
            to: {
                namespace: ['home', 'architecture']
            },
            leave: ({ current }) => leaveToProject(current.container),
            enter: ({ next }) => {
                revealProject(next.container);console.log('transition !from-detail');
            }
            //leave: ({ current }) => leaveFromProject(current.container),
            //enter: ({ next }) => {
            //    resetActiveLink();
                //gsap.from('header a', {
                //    duration: 0.6,
                //    yPercent: 100,
                //    stagger: 0.2,
                //    ease: 'power1.out'
                //});
                //animationEnterMask(next.container);
            //}
        }
    ]
});