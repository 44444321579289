import * as THREE from "../../../../_snowpack/pkg/three.js";
import gsap from "../../../../_snowpack/pkg/gsap.js";

import CreateScene from "./indexrev.js";

import vertexShader from "./glsl/vertex.js";
import fragmentShader from "./glsl/fragment.js";

let Gl;

export function newscene() {
    Gl = new CreateScene();
}

//newscene (); report to slideshow init

let mouse = new THREE.Vector2();
window.addEventListener("mousemove", (event) => {
    event.preventDefault();
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
});
let mouseOver = false,
    mouseDown = false;


const planeMaterial = new THREE.ShaderMaterial({
    vertexShader,
    fragmentShader,
    side: THREE.DoubleSide,
    defines: {
        PI: Math.PI,
    },
});
const positionArray = new Float32Array(
    [-1, -1, 0,
        1, -1, 0.0,
        0, 0.8, 0
    ]
)
var quad_uvs = [
    0.0, 0.0,
    1.0, 0.0,
    0.5, 1.0
];



// Each vertex has one uv coordinate for texture mapping
var uvs = new Float32Array(quad_uvs);
// Use the four vertices to draw the two triangles that make up the square.


const posAttrib = new THREE.BufferAttribute(positionArray, 3)
let triangleObj = new THREE.BufferGeometry();
triangleObj.setAttribute('position', posAttrib);
triangleObj.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
//triangleObj.setIndex( new THREE.BufferAttribute( indices, 1 ) );
let numFacesToDraw = 64;
triangleObj.setDrawRange(0, 36);

class GlObject extends THREE.Object3D {
    init(el) {
        this.el = el;
        this.resize();
        this.zpos = this.position.z
    }

    resize() {
        this.setBounds();
    }

    setBounds() {
        this.rect = this.el.getBoundingClientRect();

        this.bounds = {
            left: this.rect.left,
            top: this.rect.top + window.scrollY,
            width: this.rect.width,
            height: this.rect.height,
        };

        this.updateSize();
        this.updatePosition();
    }

    updateSize() {
        var heightvar = Math.min(0.9, Math.max(0.65, window.innerHeight / 1024))
        var newposz = this.zpos - (heightvar - 1) * 80
        this.camUnit = this.calculateUnitSize(Gl.camera.position.z - newposz);
        //this.camUnit = this.calculateUnitSize(Gl.camera.position.z - this.position.z);

        const x = this.bounds.width / window.innerWidth;
        const y = this.bounds.height / window.innerHeight;

        if (!x || !y) return;

        this.scale.x = this.camUnit.width * x;
        this.scale.y = this.camUnit.height * y;
    }

    calculateUnitSize(distance = this.position.z) {
        const vFov = (Gl.camera.fov * Math.PI) / 180;
        const height = 2 * Math.tan(vFov / 2) * distance;
        const width = height * Gl.camera.aspect;

        return { width, height };
    }

    updateY(y = 0) {
        const { top, height } = this.bounds;

        this.position.y = this.camUnit.height / 2 - this.scale.y / 2;
        this.position.y -= ((top - y) / window.innerHeight) * this.camUnit.height;

        this.progress = gsap.utils.clamp(0, 1, 1 - (-y + top + height) / (window.innerHeight + height));
    }

    updateX(x = 0) {
        const { left } = this.bounds;

        this.position.x = -(this.camUnit.width / 2) + this.scale.x / 2;
        this.position.x += ((left + x) / window.innerWidth) * this.camUnit.width;
    }

    updatePosition(y) {
        this.updateY(y);
        this.updateX(0);
    }
}

export default class extends GlObject {
    init(el) {
        super.init(el);
        this.geometry = triangleObj;
        this.material = planeMaterial.clone();

        this.material.uniforms = {
            uCurrTex: { value: 0 },
            uNextTex: { value: 0 },
            uTime: { value: 0 },
            uProg: { value: 0 },
            uAmplitude: { value: 0 },
            uProgDirection: { value: 0 },
            uMeshSize: { value: [this.rect.width, this.rect.height] },
            uImageSize: { value: [0, 0] },
            uMousePos: { value: [0, 0] },
            uMouseOverAmp: { value: 0 },
            uAnimating: { value: false },
            uRadius: { value: 0.08 },
            uTranslating: { value: true },
        };

        this.imageScale = 1;

        this.textures = [];

        this.raycaster = new THREE.Raycaster();
        this.mouse = new THREE.Vector2();
        this.mouseLerpAmount = 0.1;

        this.state = {
            animating: false,
            current: 0,
        };

        this.mesh = new THREE.Mesh(this.geometry, this.material);
        this.add(this.mesh);

        Gl.scene.add(this);

        this.loadTextures();
        this.addEvents();
    }

    loadTextures() {
        const manager = new THREE.LoadingManager(() => {
            this.material.uniforms.uCurrTex.value = this.textures[0];
        });
        const loader = new THREE.TextureLoader(manager);
        const imgs = [...this.el.querySelectorAll("img")];
        console.log('el', this.el);
        console.log('imgs', imgs.length);
        this.textures = new Array(imgs.length);
        imgs.forEach((img, index) => {
            loader.load(img.src, (texture) => {
                texture.minFilter = THREE.LinearFilter;
                texture.generateMipmaps = false;
                console.log('img', img);
                let post = img.getAttribute('data-sl');
                this.material.uniforms.uImageSize.value = [img.naturalWidth, img.naturalHeight];
                this.textures.splice(post, 1, texture);
                console.log(this.textures.length);
                //this.textures.push(texture);
                console.log('textures', this.textures);
            });
        });
    }

    switchTextures(index, direction) {
        if (this.state.animating) return;

        gsap.timeline({
                onStart: () => {
                    this.state.animating = true;
                    this.material.uniforms.uAnimating.value = true;
                    this.material.uniforms.uProgDirection.value = direction;
                    this.material.uniforms.uNextTex.value = this.textures[index];
                },
                onComplete: () => {
                    this.state.animating = false;
                    this.material.uniforms.uAnimating.value = false;
                    this.material.uniforms.uCurrTex.value = this.textures[index];
                    this.currentAmp = 0;
                },
            })
            .fromTo(
                this.material.uniforms.uProg, {
                    value: 0,
                }, {
                    value: 1,
                    duration: 1,
                    ease: "ease.out",
                },
                0
            )
            .fromTo(
                this.material.uniforms.uAmplitude, {
                    value: 0,
                }, {
                    duration: 0.8,
                    value: 1,
                    repeat: 1,
                    yoyo: true,
                    yoyoEase: "sine.out",
                    ease: "expo.out",
                },
                0
            );
    }

    updateTime(time) {
        this.material.uniforms.uTime.value = time;
        this.run();
    }

    addEvents() {
        this.el.addEventListener("mouseenter", () => (mouseOver = true));
        this.el.addEventListener("mouseleave", () => (mouseOver = false));
        this.el.addEventListener("mousedown", () => (mouseDown = true));
        this.el.addEventListener("mouseup", () => (mouseDown = false));
    }

    scaleImage(direction) {
        const imageTl = gsap.timeline({
            defaults: {
                duration: 1.2,
                ease: "elastic.out(1, 1)",
                onUpdate: () => {
                    this.resize();
                },
            },
        });
        if (direction == "up") {
            imageTl.to(this.el, {
                scale: window.innerHeight / 600,
            });
        } else if (direction == "down") {
            imageTl.to(this.el, {
                scale: 1,
            });
        }
    }

    run() {
        let m = mouseOver ? mouse : new THREE.Vector2(0, 0);
        this.mouse.lerp(m, this.mouseLerpAmount);

        this.raycaster.setFromCamera(this.mouse, Gl.camera);
        let intersects = this.raycaster.intersectObject(this.mesh);
        if (intersects.length > 0) {
            this.material.uniforms.uMousePos.value = [intersects[0].uv.x, intersects[0].uv.y];
        }

        if (mouseOver) {
            this.material.uniforms.uMouseOverAmp.value = THREE.MathUtils.lerp(this.material.uniforms.uMouseOverAmp.value, 1, 0.08);
            this.mouseLerpAmount = THREE.MathUtils.lerp(this.mouseLerpAmount, 0.1, 0.5);
        } else {
            this.material.uniforms.uMouseOverAmp.value = THREE.MathUtils.lerp(this.material.uniforms.uMouseOverAmp.value, 0, 0.08);
            this.mouseLerpAmount = THREE.MathUtils.lerp(this.mouseLerpAmount, 0, 0.5);
        }

        if (mouseOver && mouseDown) {
            this.material.uniforms.uRadius.value = THREE.MathUtils.lerp(this.material.uniforms.uRadius.value, 1, 0.01);
        } else if (mouseOver && !mouseDown) {
            this.material.uniforms.uRadius.value = THREE.MathUtils.lerp(this.material.uniforms.uRadius.value, 0.08, 0.08);
        }

        if (this.state.animating) {
            this.material.uniforms.uMouseOverAmp.value = THREE.MathUtils.lerp(this.material.uniforms.uMouseOverAmp.value, 0, 0.1);
        }
    }
}