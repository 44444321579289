

class DetailPage {
	namespace = 'detail';

	beforeEnter = data => {
		console.log(data, 'on project detail');
		let event = new MouseEvent("click");
		//const canva = document.querySelector(".dom-gl");
		//canva.remove();
		//console.log('canva',canva);
		//if (canva.length > 0) {
		//	canva.remove();
		//}
	};
}

export default new DetailPage();
