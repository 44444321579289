import { homeSlide } from "../../three-slider/index.js";

class Home {
	namespace = 'home';

	beforeEnter = data => {
		console.log(data, 'on home page');
		homeSlide();
	};
	beforeLeave = () => {
		document.querySelectorAll(".slider__resT")[0].click();
	};
}

export default new Home();
